; $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
    console.error(message);
};

$.extend(true, $.fn.dataTable.defaults, {
    ajax: {
        error: function (xhr, status, error) {
            if (xhr.status == 401 || xhr.status == 403) {
                window.location.reload();
            } else {
                console.log({ xhrResponseText: xhr.responseText, xhrStatusText: xhr.statusText, status: status, error: error });

                $(document).Toasts('create', {
                    autohide: false,
                    title: 'Error occurred (' + xhr.status + '). Please refresh the page and try again.',
                    icon: 'fas fa-times-circle',
                    class: 'bg-danger'
                });
            }
        }
    }
});
$(function () {
    var errors = $('.field-validation-error').closest('.form-group');

    if (!errors.length) return;

    errors.addClass('form-group-invalid');

    var error = errors.eq(0);

    var top = error.offset().top;

    $('html, body').animate({
        scrollTop: top
    }, 500);
});
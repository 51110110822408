// Refresh form sections
$(function () {
    var form = $('[data-refresh]').closest('form');

    form.on('change', '[data-refresh] select', function () {
        var e = $(this),
            gr = e.closest('[data-refresh]'),
            url = gr.attr('data-refresh')
            ;

        $.ajax({
            method: 'POST',
            url: url,
            data: form.serialize(),
            success: function (html) {
                var $html = $(html);

                $html
                    .find('[data-refreshes]')
                    .addBack('[data-refreshes]')
                    .each(function (_i, _e) {
                        var item = $(_e),
                            selector = item.attr('data-refreshes'),
                            replace = $(document).find(selector)
                            ;

                        replace.each(function () {
                            var el = $(this);

                            el.closest('.form-group').replaceWith(item.clone().attr('data-refreshes', ''));
                        });
                    });

                form.trigger('project.refreshed');
            }
        });
    });
});
// Confirm
$(function () {
    $(document).on('click', '[data-toggle=confirm]', function (ev) {
        ev.preventDefault();

        var lines = [
            '<div class="modal fade" id="modal-confirm" tabindex="-1" role="dialog" aria-hidden="true">',
            '   <div class="modal-dialog">',
            '       <div class="modal-content">',
            '            <div class="modal-header">',
            '                <h4 class="modal-title"></h4>',
            '            </div>',
            '            <div class="modal-body">',
            '            </div>',
            '            <div class="modal-footer justify-content-between">',
            '                <button type="button" class="btn btn-cancel" data-dismiss="modal"></button>',
            '                <a href="#" class="btn btn-ok"></a>',
            '            </div>',
            '        </div>',
            '    </div>',
            '</div>',
        ];

        var $e = $(this),
            $t = $(lines.join('')),
            $ok = $('.btn-ok', $t),
            title = $e.attr('data-title'),
            text = $e.attr('data-text'),
            type = $e.attr('data-type'),
            ok = $e.attr('data-ok'),
            cancel = $e.attr('data-cancel'),
            href = $e.attr('href') || $e.attr('data-href')
            ;

        if (title) $('.modal-title', $t).html(title);
        if (text) $('.modal-body', $t).html(text);
        if (type) { $t.find('.modal-content').addClass('bg-' + type); $t.find('.btn').addClass(type === 'warning' ? 'btn-outline-dark' : 'btn-outline-light'); }
        if (cancel) $('.btn-cancel', $t).html(cancel);
        if (ok) $ok.html(ok);
        if (href) $ok.attr('href', href);

        $t.on('hidden.bs.modal', function () { $t.remove(); });
        $t.modal('show');
    });
});
// Photo component
$(function () {
    var photosRemove = $('[data-toggle=photo][data-remove]');

    photosRemove.append('<button class="btn btn-sm btn-danger" data-toggle="remove"><i class="fa fa-trash"></i></button>');

    photosRemove.on('click', '[data-toggle=remove]', function (ev) {
        ev.stopPropagation();

        var e = $(this),
            parent = e.closest('[data-remove]'),
            url = parent.attr('data-remove')
            ;

        e.prop('disabled', true);

        $.ajax({
            method: 'DELETE',
            url: url
        })
        .done(function (data, textStatus, jqXHT) {
            parent.remove();

        })
        .always(function () {
            e.prop('disabled', false);
        });
    });

    $('[data-toggle=photo][data-download]').each(function (ev) {
        var e = $(this),
            url = e.attr('data-download')
            ;

        if (url) e.append('<a class="btn btn-sm btn-primary" href="' + url + '" target="_blank" data-toggle="download" onclick="event.stopPropagation();"><i class="fa fa-download"></i></a>');
    });

    $('[data-toggle=photo][data-remote]:not([data-gallery])').attr('data-gallery', 'auto-grouping');

    $(document).on('click', '[data-toggle=photo][data-remote]', function () {
        $(this).ekkoLightbox({ alwaysShowClose: true, showArrows: true });
    });
});
$(function () {

    var settings = {
        root: null,
        badge: null,
        url: null,
        urlCancel: null,
        urlDetails: null,
        interval: window.debug ? 1000000000 : 5000,
        statusCancelled: 8,
        statusError: 5,
        template:
            '<li class="cancelable">' +
            '<a href="javascript:void(0)">' +
            '<h6 class="control-sidebar-subheading"></h6>' +
            '<div class="progress progress-xxs"><div class="progress-bar progress-bar-danger progress-bar-striped active" style="width: 70%"></div></div>' +
            '<span class="control-sidebar-subfooter"></span>' +
            '</a>' +
            '</li>',
    };

    var utility = {
        update: function () {
            if (!settings.url) return;

            $.ajax({
                type: 'GET',
                url: settings.url
            }).done(function (data) {
                settings.root
                    .empty()
                    .append($.map(data.data, utility.build));

            }).fail(function (jqXHR, text, error) {
                settings.root.empty();
            });
        },
        cancel: function () {
            if (!settings.urlCancel) return;

            var r = $(this),
                id = r.closest('li').attr('data-id');

            $.ajax({
                type: 'GET',
                url: settings.urlCancel,
                data: { id: id }
            }).done(function (data) {
                utility.update();
            }).fail(function (jqXHR, text, error) {
            }).always(function () { });
        },
        build: function (e, i) {
            var r = $(settings.template);

            r.attr('data-id', e.id);
            r.find('.control-sidebar-subheading')
                .empty()
                .append('<span>'
                    + e.job_type
                    /*+ '<span class="pull-right" data-toggle="cancel"><i class="fa fa-times"></i></span>'*/
                    + '</span>'
                    + '</br>'
                    + '<span>' + e.file_name + '</span>'
                );
            r.find('.progress').toggle(e.percentage >= 0);
            r.find('.progress-bar').css('width', (e.percentage >= 0 ? e.percentage : 0) + '%');
            r.find('.control-sidebar-subfooter')
                .empty()
                .append(utility.getStatus(e));

            return r;
        },
        getStatus: function (e) {
            var prefix = (e.report_error ? '<a href="' + settings.urlDetails + '/' + e.id + '" target="_blank">' : '');
            var suffix = (e.report_error ? 'Error &rsaquo;</a>' : '');

            return $('<span>'
                + prefix
                + (e.finished ? 'Done' : e.report_error ? '' : 'In progress')
                + suffix
                + '<span class="float-right">' + moment(e.inserted).fromNow() + '</span></span>');
        }
    };

    settings.root = $('[data-toggle=tasks]');
    settings.url = settings.root.data('url-get');
    settings.urlCancel = settings.root.data('url-cancel');
    settings.urlDetails = settings.root.data('url-details');
    settings.texts = settings.root.data('texts');

    settings.root.on('click', '[data-toggle=cancel]', utility.cancel);

    clearInterval(settings.intervalId);
    settings.intervalId = setInterval(utility.update, settings.interval);
    utility.update();

    $(window).resize(function () {
        var bodyheight = $(this).height();
        settings.root.height(bodyheight - 165);
    }).resize();
});
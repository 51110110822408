// Ajax modal
(function () {
    if (!window.__)
        window.__ = {};

    var lines = [
        '<div class="modal fade" id="modal-ajax" tabindex="-1" role="dialog" aria-hidden="true">',
        '   <div class="modal-dialog">',
        '       <div class="modal-content">',
        '            <div class="modal-header">',
        '                <h4 class="modal-title"></h4>',
        '                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>',
        '            </div>',
        '            <div class="modal-body">',
        '               <div class="text-center">',  
        '                  <i class="fas fa-circle-notch fa-spin fa-lg"></i>',
        '               </div>',  
        '            </div>',
        '            <div class="modal-footer justify-content-end">',
        '                <button type="button" class="btn btn-primary btn-cancel" data-dismiss="modal"></button>',
        '            </div>',
        '        </div>',
        '    </div>',
        '</div>',
    ];

    window.__.modal = function (title, type, dismiss, url, error) {
        var $t = $(lines.join(''))
            ;

        if (title) $('.modal-title', $t).html(title);
        if (type) $t.find('.modal-content').addClass('bg-' + type);
        if (dismiss) $('.btn-cancel', $t).html(dismiss);

        $t.find('.modal-body').load(url, function (response, status, xhr) {
            if (status == 'error') {
                $t.modal('hide');

                $(document).Toasts('create', {
                    autohide: true,
                    delay: 600000,
                    title: error,
                    class: 'bg-danger'
                });
            }
        });
        $t.on('hidden.bs.modal', function () { $t.remove(); });
        $t.modal('show');
    };
})();